import React from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const GraphicElements = () => {
    return(
        <Layout>
            <SEO title="Grafiske elementer" />
            <div className="wrap" role="document">
                <div className="content">

                    <main className="main" id="main">
                        <header className="header">
                            <div className="container">
                                <div className="header__caption">
                                    <h1>Grafiske elementer</h1>
                                </div>
                            </div>
                        </header>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="submenu">
                                        <ul className="submenu__list">
                                            <li className="submenu__item"><a href="#column">Søyle/kurvemønster</a></li>
                                            <li className="submenu__item"><a href="#logo">Logo-S</a></li>
                                            <li className="submenu__item"><a href="#logo-shape">Logo-form</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9">

                                    <p className="ingress lead">
                                        Tre grafiske elementer er tilgjengelige for å komplimentere de andre definerte profilelmentene. De kan bli brukt sammen eller hver for seg. Foruten til trykk og digitalt bruk, vil alle disse grafiske elementene egne seg godt både til preg og som lakkerte former i trykksaker.
                                    </p>

                                    <hr/>

                                    <div id="column" className="section">
                                        <h2>Søyle/kurvemønster</h2>
                                        <a href="https://sagaservices.blob.core.windows.net/downloads/graphic-elements/Monster_Bolgestreker_Saga.zip" className="btn button button--download"><i className="fa fa-arrow-down icon--underline"></i> Last ned mønsterpakke</a>
                                        <p>Dette mønsteret er inspirert av grafiske kurver og søylediagrammer. Strekene gir også hint om linjer i et regneark.</p>
                                        <p>
                                            Mønsteret kan brukes både i hvitt og i sort versjon, og kan stå på hvitt eller mot bilder og bakgrunner. Når det står mot bilder og bakgrunner kan det brukes en viss gjennomskinnelighet på mønsteret. Dette for å skape en mer integrert effekt der det trengs.
                                        </p>
                                        <p>Det kan brukes både horisontalt og vertikalt, og kan legges tvers over hele siden, eller bare som en del av denne. </p>
                                        <p>
                                            Det kan også brukes i forskjellig forstørrelser, men ikke så smått eller stort at “spalte-effekten” som det søkes å oppnå, uteblir.
                                        </p>
                                        <img src="../img/sections/grafiske-profilelementer/element_1.jpg" className="img-fluid" alt="Saga KL Profilmanual Grafisk element søyler 900x600"/>
                                        <br/><br/>
                                        <img src="../img/sections/grafiske-profilelementer/element_3.jpg" className="img-fluid" alt="Saga KL Profilmanual Grafisk element søyler 900x600"/>
                                    </div>
                                    <hr/>
                                    <div id="logo" className="section">
                                        <h2>Logo-S</h2>
                                        <a href="https://sagaservices.blob.core.windows.net/downloads/graphic-elements/Logo-S.zip" className="btn button button--download"><i className="fa fa-arrow-down icon--underline"></i> Last ned logo-s</a>
                                        <p>
                                            Vi bruker logofiguren “S” til å skape grafiske effekter. Som regel vil den bare sees som utsnitt, gjerne kraftig forstørret, men kan også stå som et ikon. Da vises hele formen. Kan brukes i alle profilfarger og graderinger, men gjerne helst i grått.
            Dette bastante symbolet står godt sammen med det lette og luftige søyle/kurvemønsteret som beskrevet over.
                                        </p>
                                        <p>Obs! Kan gi en kraftfull og dominerende effekt. Brukes med fornuft.</p>
                                        <img src="../img/sections/grafiske-profilelementer/element_2.jpg" className="img-fluid" alt="Saga KL Profilmanual Grafisk element logo-s 900x600"/>
                                        <br />
                                        <br />
                                        <img src="../img/sections/grafiske-profilelementer/element_4.jpg" className="img-fluid" alt="Saga KL Profilmanual Grafisk element kombinasjon søyler og logo-s 900x600"/>
                                    </div>
                                    <hr/>
                                    <div id="logo-shape" className="section">
                                        <h2>Logo-form</h2>
                                        <a href="https://sagaservices.blob.core.windows.net/downloads/graphic-elements/Logo-form.zip" className="btn button button--download"><i className="fa fa-arrow-down icon--underline"></i> Last ned logo-fom</a>
                                        <p>
                                            Et omriss av logoformen kan brukes når vi vil sette fokus. Kan legges på både tekst og bilder, og kan brukes som ramme eller med heldekkende farge.
                                            
                                        </p>
                                        <p>Ekstra sterk blir symbolikken dersom bildeutsnitt og formen sammen utgjør bokstaven S for Saga KL.
                                        </p>
                                    </div>
                                    <img className="img-fluid" src="../img/sections/grafiske-profilelementer/element_5.jpg" alt="Saga KL Profilmanual VERTIKAL LOGO 900x600"/>
                                    </div>
                                </div>
                            </div>
                    </main>

                </div>
            </div>        
        </Layout>
    )
}

export default GraphicElements